import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { HelmetDatoCms } from "gatsby-source-datocms"

import { Section, Container, Content } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import Layout from "@utils/layout"

const About = ({ data }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsAbout.seoMetaTags} />
      <Section lgTop md data-datocms-noindex>
        <Container containerMedium>
          <Story>
            <Image>
              <GatsbyImage
                image={data.datoCmsAbout.headerImage.gatsbyImageData}
                alt={data.datoCmsAbout.headerImage.alt}
                title={data.datoCmsAbout.headerImage.title}
              />
            </Image>
            <Heading html="h1" level="huge">
              {data.datoCmsAbout.title}
            </Heading>
            <Content
              dangerouslySetInnerHTML={{
                __html: data.datoCmsAbout.content,
              }}
            />
          </Story>
        </Container>
      </Section>
    </Layout>
  )
}

export default About

export const fetchAbout = graphql`
  query fetchAbout {
    datoCmsAbout {
      title
      content
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      headerImage {
        alt
        title
        gatsbyImageData(
          width: 1200
          layout: CONSTRAINED
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
        )
      }
    }
  }
`

const Image = styled.div`
  margin-bottom: var(--spacing--sm);
`

const Story = styled.div`
  position: relative;

  &:before {
    background-color: var(--gray--00);
    border-radius: 100rem;
    content: "";
    height: 25vw;
    left: -12.5vw;
    position: absolute;
    top: 40%;
    width: 25vw;
    z-index: -1;
  }

  h3:first-of-type {
    margin-top: var(--spacing--sm);
  }
`
